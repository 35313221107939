import { registerVueControllerComponents } from "@symfony/ux-vue";
import * as Sentry from "@sentry/vue";

/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import "./styles/app.css";

// start the Stimulus application
import "./bootstrap";

// Registers Vue.js controller components to allow loading them from Twig
//
// Vue.js controller components are components that are meant to be rendered
// from Twig. These component can then rely on other components that won't be
// called directly from Twig.
//
// By putting only controller components in `vue/controllers`, you ensure that
// internal components won't be automatically included in your JS built file if
// they are not necessary.
// registerVueControllerComponents(require.context('./vue/controllers', true, /\.vue$/));

// If you prefer to lazy-load your Vue.js controller components, in order to keep the JavaScript bundle the smallest as possible,
// and improve performance, you can use the following line instead:
registerVueControllerComponents(
  require.context("./vue/controllers", true, /\.vue$/, "lazy")
);

document.addEventListener("vue:before-mount", (event) => {
  const {
    app, // The Vue application instance
  } = event.detail;

  Sentry.init({
    app,
    dsn: process.env.SENTRY_DSN_VUE,
    environment: process.env.SENTRY_VUE_ENVIRONMENT,
    release: process.env.GIT_SHA,
    integrations: [
      // new Sentry.BrowserTracing({
      //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // }),
      // On the manage booking page we get: Uncaught Error: Multiple Sentry Session Replay instances are not supported
      // new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
});
